@import 'var';

.card-list{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -5px;
}

.card{
    background: #fff;
    box-shadow: 0 8px 8px rgba(172, 175, 180, 0.1);
    flex: 0 0 calc(33.333% - 10px);
    max-width: calc(33.333% - 10px);
    margin: 0 5px 20px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    transition: $transition;
    &__pic{
        width: 70px;
        height: 70px;
        background-color: #80A8FF;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 33px 33px;
        position: relative;
        flex: 0 0 70px;
        &_brand{
            background-image: url(../images/pic_brand.png);
        }
        &_client{
            background-image: url(../images/pic_client.png);
        }
        &_dot{
            background-image: url(../images/pic_dot.png);
        }
        &_order{
            background-image: url(../images/pic_order.png);
        }
        &_page{
            background-image: url(../images/pic_page.png);
        }
        .material-icons{
            position: absolute;
            top: -5px;
            right: -15px;
            width: 36px;
            height: 36px;
            line-height: 36px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            background-color: #FC738D;
            color: #fff;
            font-size: 25px;
            box-shadow: 0 4px 8px rgba(58, 66, 79, 0.3);
            text-align: center;
            cursor: pointer;
            transition: $transition;
            &:hover{
                transform: rotate(180deg);
                box-shadow: none;
            }
            &.bg_blue{
                background: $main;
            }
            &.bg_white{
                background: #fff;
                color: $main;
            }
        }
        &.bg_darkblue{
            background-color: $main;
        }
    }
    &__desc{
        flex: 1 0 auto;
        max-width: calc(100% - 100px);
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        align-content: space-around;
    }
    &__title{
        color: $main;
        font-size: 24px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 140%;
        //flex: 0 0 100%;
        display: inline-block;
        position: relative;
        &:before{
            content: "";
            width: 0;
            height: 1px;
            position: absolute;
            bottom: 0px;
            right: 0;
            background: rgba(50, 113, 254, 0.56);
            transition: $transition;
        }

    }
    &__subtitle{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 0 100%;
        color: #8b93a6;
    }
    &:hover{
        box-shadow: 0 3px 14px rgba(172, 175, 180, 0.6);
        .card__title:before {
            width: 100%;
            left: 0;
        }
    }
}































